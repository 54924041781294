import { useContext, useRef, useState } from "react";
import EditBox from "../../UI/Atoms/EditBox/EditBox";
import "./Style.css";
import Button from "../../UI/Atoms/Button/Button";
import { updateIntoduced, updateName } from "../../Data/User";
import Spinner from "../../UI/Atoms/Spinner/Spinner";
import { JSX } from "react/jsx-runtime";
import AddListsDlg from "../../UI/Organizm/AddListsDlg/AddListsDlg";
import { useOverlay } from "../../Context/OverlayProvider";
import Logo from "../../UI/Atoms/Logo/Logo";
import { ThemeContext } from "../../Context/Color";
import { LocalStorage } from "../../Util/localStorage";
import Icon from "../../UI/Atoms/Icon/Icon";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

type IntroProps = {
  isMobile: boolean;
  userID: string;
  userEmail: string;
};

const IntroDlg = (props: IntroProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const themeContext = useContext(ThemeContext);

  const storage = new LocalStorage();

  const { addOverlay } = useOverlay();

  const [loading, setLoading] = useState(false);

  const [dark, setDarkMode] = useState(themeContext?.isDarkTheme);

  const { width, height } = useWindowSize();

  const onTheme = () => {
    themeContext?.setDarkMode(!dark);
    setDarkMode(!dark);
    storage.setValue("THEME", !dark ? "2" : "1");
  };

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const onGetStarted = async () => {
    setLoading(true);
    let retries = 0;
    let val = inputRef.current?.value;

    while (retries < 7) {
      try {
        const isUpdated = await updateIntoduced(
          true,
          props.userEmail,
          props.userID
        );
        if (isUpdated) {
          setLoading(false);
          if (val) updateName(val, props.userID);
          return;
        }
        // If successful, exit the function.
      } catch (error) {
        console.error(
          "Error in onGetStarted, attempt number " + (retries + 1),
          error
        );
        retries++;
        await sleep(500); // Wait for half a second before the next retry.
      }
    }

    // If code reaches here, it means the maximum retry limit was reached without success.
    console.error("Max retries reached in onGetStarted.");
    // Optionally show some user-friendly error message.
  };
  const onAddLists = () => {
    addOverlay(
      <AddListsDlg
        textColor={themeContext?.textColor!}
        userID={props.userID}
        isMobile={props.isMobile}
        onSave={(theme: number) => {}}
        onCancel={() => {}}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,

        marginTop: 100,
      },
      true
    );
  };

  return (
    <div
      style={{
        backgroundColor: themeContext?.overlayBackground,
        color: themeContext?.textColor,
        justifyContent: props.isMobile ? "" : "",
      }}
      className="main_intro"
    >
      <Confetti width={width} height={height} recycle={false} />
      <Logo
        smallLogo={props.isMobile}
        alignCenterX={true}
        alignCenterY={false}
        color={themeContext?.textColor!}
      />
      <div
        style={{
          justifyContent: props.isMobile ? "" : "center",
          alignItems: props.isMobile ? "" : "center",
        }}
        className="intro"
      >
        <div
          style={{ padding: props.isMobile ? "6px" : "40px" }}
          className="content"
        >
          <div className="header">
            {props.userEmail}, It's great to meet you 😀
          </div>
          <EditBox
            type="text"
            placeholder="What's your name? "
            id="name_box"
            focus={true}
            disableBorder={true}
            EditRef={inputRef}
          />
          <div style={{ marginTop: "8px" }}>
            <Button
              centerText={false}
              fontSize="24px"
              id="button_theme"
              iconComponent={
                <Icon darkModeIcon={!dark} brightModeIcon={dark} />
              }
              label={dark ? "Use bright mode" : "Use dark mode"}
              textButton={true}
              onClick={onTheme}
            />
          </div>
          <div className="add_lists">
            <Button
              centerText={true}
              fontSize="24px"
              id="button_add_lists"
              label="👋 Add new lists!"
              textButton={true}
              onClick={onAddLists}
            />
          </div>
          <div className="get_started">
            <Button
              centerText={true}
              fontSize="24px"
              id="button_get_started"
              label="Get Started 👉🏿"
              textButton={false}
              onClick={onGetStarted}
            />
          </div>
          {loading && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntroDlg;
