import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import { ThemeContext } from "./Color";
import Icon from "../UI/Atoms/Icon/Icon";

// 1. Snackbar types and interfaces
interface Snackbar {
  id: number;
  message: string;
  duration: number;
  actionText?: string;
  actionCallback?: () => void;
}

interface SnackbarProviderProps {
  children: React.ReactNode;
}

interface SnackbarContextType {
  addSnackbar: (
    message: string,
    durationInSeconds?: number,
    actionText?: string,
    actionCallback?: () => void,
    closeButton?: boolean
  ) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

// 2. Hook for using Snackbar
export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

// 3. SnackbarProvider
let snackbarID = 0; // to give unique IDs

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children,
}) => {
  const [snackbar, setSnackbar] = useState<Snackbar | null>(null);
  const [closeMsg, setCloseMsg] = useState(false);
  const [maxHeight, setMaxHeight] = useState("1000px");
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const themeContext = useContext(ThemeContext);

  const addSnackbar = useCallback(
    (
      message: string,
      durationInSeconds: number = 3,
      actionText?: string,
      actionCallback?: () => void,
      closeButton?: boolean
    ) => {
      setMaxHeight("1000px");
      const id = snackbarID++;
      setCloseMsg(closeButton || false);

      if (timeoutRef.current) {
        // Clear the previous timeout if it exists
        clearTimeout(timeoutRef.current);
      }

      setSnackbar({
        id,
        message,
        duration: durationInSeconds * 1000,
        actionText,
        actionCallback,
      });

      timeoutRef.current = setTimeout(() => {
        hideSnackbar(true);
      }, durationInSeconds * 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const snackbarContainerStyle = {
    position: "fixed" as "fixed",
    bottom: 0,
    left: closeMsg ? "" : "50%",
    transform: closeMsg ? "" : "translateX(-50%)",
    zIndex: 1000,
    width: closeMsg ? "100%" : "",
    display: "flex",
    alignItems: "center",
    justifyConent: "space-between",
    transition: "max-height 1.5s", // Add transition for max-height property
    maxHeight: snackbar ? maxHeight : "0",

    overflow: "hidden", // Hide content when height is 0
  };

  const snackbarStyle: React.CSSProperties = {
    backgroundColor: themeContext?.snackbar.background,
    color: themeContext?.snackbar.text,
    width: closeMsg ? "100%" : "",
    textAlign: closeMsg ? "center" : "left",
    padding: closeMsg ? "10px" : "10px 20px",
    borderRadius: closeMsg ? "" : 5,
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",

    display: closeMsg ? "flex" : "",

    flexDirection: !closeMsg ? "row" : ("column" as "column"), // Explicitly specify the type
  };

  const actionButtonStyle = {
    marginTop: closeMsg ? "8px" : "",
    color: themeContext?.snackbar.action,
    cursor: "pointer",
    alignItems: "center",
    marginLeft: closeMsg ? "" : "10px",

    justifyContent: "center",
  };

  const hideSnackbar = useCallback((animate: boolean) => {
    if (animate) {
      setMaxHeight("0px");
      timeoutRef.current = setTimeout(() => {
        setSnackbar(null);
      }, 1200);
    } else {
      setSnackbar(null); // Hide without animation
    }
  }, []);

  return (
    <SnackbarContext.Provider value={{ addSnackbar }}>
      {children}
      <div style={snackbarContainerStyle}>
        {snackbar && (
          <div style={snackbarStyle}>
            {closeMsg && (
              <div
                style={{
                  cursor: "pointer",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "end",
                }}
                onClick={() => {
                  hideSnackbar(false);
                }}
              >
                <Icon closeIcon={true} />
              </div>
            )}
            {snackbar.message}
            {snackbar.actionText && (
              <span
                style={actionButtonStyle}
                onClick={() => {
                  if (snackbar.actionCallback) snackbar.actionCallback();
                  hideSnackbar(false);
                }}
              >
                {snackbar.actionText}
              </span>
            )}
          </div>
        )}
      </div>
    </SnackbarContext.Provider>
  );
};
